/*! BODY FONTS */
/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: local('PT Serif'), local('PTSerif-Regular'), url(App/Assets/font/PTSerif_Latin_Ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: local('PT Serif'), local('PTSerif-Regular'), url(App/Assets/font/PTSerif_Latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*! FOOTER & HEADER MENU FONTS */
/* latin-ext */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url(App/Assets/font/Inconsolata_Latin_Ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url(App/Assets/font/Inconsolata_Latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*! LOGO FONT */
/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  src: local('Cinzel Regular'), local('Cinzel-Regular'), url(App/Assets/font/Cinzel_Latin_Ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  src: local('Cinzel Regular'), local('Cinzel-Regular'), url(App/Assets/font/Cinzel_Latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --spacer: 20px;
  --half-spacer: 10px;
  --quarter-spacer: 5px;
  --border-radius: 3px;

  --three-d-shadow:
    inset 0 -1px rgba(0,0,0,.1),
    0 1px 1px 0 rgba(0,0,0,.06),
    0 2px 5px 0 rgba(0,0,0,.2);

  --body-text-color: var(--charcoal);

  --layout-bg-color: #EDEEF0;
  --layout-box-shadow: none; /* 0 0.1em 0.5em 0 rgba(0,0,0,.28); */

  --logo-text-color: var(--white);

  --header-height: 56px;
  --nav-height: 41px;

  --input-width: 40px;
  --input-height: 40px;
  --input-border-color: var(--smoke);

  --title-color: var(--cobalt-blue-3);
  --title-shadow: none; /* 0px 1px 0px var(--gray); */

  --section-waterline-shadow:
    inset 0 1px rgba(0,0,0,.1),
    0 1px 1px 0 rgba(0,0,0,.06),
    0px 0px 2px 0px rgba(0,0,0,.2);

  --navbar-bg-color: var(--blue-gray);
  --navbar-item-hover-color: var(--all-white);

  --nav-item-hover-color: var(--red-1);

  --nav-mode-item-box-shadow:
    inset 0 1px rgba(0,0,0,.1),
    0 1px 1px 0 rgba(0,0,0,.06),
    0 2px 5px 0 rgba(0,0,0,.2);
  --nav-mode-waterline-height: 2px;

  --nav-source-item-box-shadow:
    inset 0 -1px rgba(0,0,0,.1),
    0 1px 1px 0 rgba(0,0,0,.06),
    0 .8px 2px 0 rgba(0,0,0,.2);

  --content-box-shadow: var(--three-d-shadow);

  --results-title-height: 58px;

  --definitions-col1-width: 28px;
  --definitions-col2-width: 16px;
  --line-title-shadow: none; /* 0px 1px 0px var(--smoke); */
  --word-hover-color: var(--cobalt-blue-3);

  --interaction-mode-hotkey-off-text-color: var(--charcoal);
  --interaction-mode-hotkey-on-text-color: var(--red-1);

  --dimmer-handle-ring-color: var(--layout-bg-color); /* ? for transparency in light mode */
  --dimmer-handle-bg-color: var(--layout-bg-color); /* ? for transparency in light mode */
  --dimmer-bar-bg-color: linear-gradient(to right, var(--layout-bg-color), var(--layout-bg-color)); /* ? for transparency in light mode */

  --primary-button-color: var(--blue-gray);

  --scrollbar-width: 6px;
  --scrollbar-height: 3px;
  --scrollbar-color: var(--blue-gray);
  --scrollbar-track-shadow: inset 0 0 3px rgba(0,0,0,0.2);
  --scrollbar-thumb-shadow: inset 0 0 3px var(--water-green);

  --image-gallery-navbtn-wrapper-dims: 36px;
  --image-gallery-navbtn-dims: 28px;
  --image-gallery-navbtn-shadow: var(--three-d-shadow);

  --highlight-menu-height: 40px;
  --highlight-menu-y-margin: 0;
  --highlight-menu-x-margin: 4px;
  --highlight-menu-y-padding: 0;
  --highlight-menu-x-padding: 6px;
  --highlight-menu-color: var(--all-white);
  --highlight-menu-bg-color: var(--cobalt);
  --highlight-menu-separator-color: var(--dark-gray);

  --loading-dots-font-size: 11px;
  --loading-dots-spacer: 5px;

  --selected-bg-color: var(--jade-green);
  --selected-color-2: var(--cobalt-blue-3);
  --selected-color: var(--red-1);
  --selected-shadow: none; /* 0px 1px 0px var(--gray); */
  --selected-source-bg-color: var(--water-green);

  --filter-color: var(--red-1);

  --searched-text-color: var(--red-1);
  --searched-text-shadow: none; /* 0px 1px 0px var(--dark-gray); */

  --emoji-color: 0 0 0 var(--blue-gray);
  --emoji-heart-color: var(--selected-color);
  --emoji-peace-color: var(--selected-color);
  --emoji-font-size: 14px;

  /* fonts */
  --font-size: 16px;
  --bold-font: 600;
  --muted-font-size: 14px;

  /* color palette */
  --pitch-black: #000;
  --eerie-black: #11151C;
  --charcoal: #262625;
  --light-charcoal: #30302f;
  --cobalt: #48494B;
  --dark-gray: #8E8E93;
  --blue-gray: #9fb0bf;
  --gray: #B9BBB6;
  --smoke: #E2E3E1;
  --dirty-white: #EDEEF0;
  --fume-white: #F7FAF9;
  --white: #FEFEFE;
  --all-white: #FFF;

  --yellow-1: #ffda56;
  --yellow-2: #ffc324;
  --yellow-3: #fae6dd;

  --petro-green: #1A6E5D;
  --jade-green: #CDFCE8;
  --water-green: #EFF8F7;

  /* --cobalt-blue-1: #213242; */
  /* --cobalt-blue-2: #1c3748; */
  --cobalt-blue-3: #055169;

  --red-1: #941a00;
  /* --red-2: #d53500; */

  --brick-1: #f65d47;
  --brick-2: #de6600;

  --purple-0: #5C2599;
  --purple-1: #7537B6;
  --purple-2: #8F53CC;
  --pink: #BA87F3;
}

.darkmode--activated {
  --navbar-bg-color: #f0ffb9; /* violet = #0E0145 */
  --navbar-item-hover-color: var(--body-text-color); /* white */
  --logo-text-color: var(--body-text-color); /* white */
  --title-color: #4fff3d; /* royalty = #AF01C1 */
  --searched-text-color: #b35d47; /* turq = #4BA1B7*/
  --selected-color: #0fc351; /* pink = #EF3BAD */
  --nav-item-hover-color: #0fc351; /* pink = #EF3BAD */
  --interaction-mode-hotkey-on-text-color: #0fc351; /* pink = #EF3BAD */

  --word-hover-color: var(--pitch-black);
  --selected-color-2: var(--pitch-black);

  --dimmer-handle-ring-color: var(--eerie-black);
  --dimmer-handle-bg-color: var(--eerie-black);
  --dimmer-bar-bg-color: linear-gradient(to right, rgba(18, 18, 18, .82), rgba(18, 18, 18, 1));
}

/* -- TEXT SELECTION -- */
::-moz-selection {
  background: var(--selected-bg-color);
  color: var(--selected-color-2);
}
::selection {
  background: var(--selected-bg-color);
  color: var(--selected-color-2);
}

body {
    /** FONT **/
    font-family: 'PT Serif' !important;
    font-size: var(--font-size) !important;
    text-size-adjust: 100%;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: unset !important;
}

body,
.rs-input {
  color: var(--body-text-color) !important;
}

a {
  color: var(--title-color) !important;
}

.App {
  /* text-align: center; */
}

.layout {
  -webkit-box-shadow: var(--layout-box-shadow);
  box-shadow: var(--layout-box-shadow);
  border-radius: var(--border-radius);
  background-color: var(--layout-bg-color); /* var(--dirty-white); */
  margin: var(--spacer) auto; /* horiz. centering */
  height: calc(100vh - (var(--spacer) * 2)); /* vert. centering */
}
@media only screen
  and (max-width: 1024px) { /* ipad pro */
  .layout {
    width: calc(100vw - (var(--spacer) * 2)); /* full-width */
  }
}
@media only screen
  and (min-width : 1025px)
  and (max-width : 1366px) { /* ipad pro, mdpi laptops */
    .layout {
      width: 85vw;
    }
}
@media only screen
  and (min-width: 1367px) { /* hd laptops, desktops */
  .layout {
    width: 75vw;
  }
}

/* styles that makes the app layout fit the full viewport height */
.rs-container {
	height: 100%;
}
.rs-header {
	height: var(--header-height);
}
.layout .rs-container > .rs-content {
  height: calc(100% - var(--header-height) - (var(--spacer) * 4) - 3px);
  -webkit-box-shadow: var(--content-box-shadow);
  box-shadow: var(--content-box-shadow);
  /* minus the header, footer, its total (top and bottom) margins & paddings */
}
.rs-flex-box-grid-start {
	height: 100%
}
.layout .rs-container > .rs-content .left-side,
.layout .rs-container > .rs-content .right-side {
	height: 100%
}

.layout
  .rs-container >
    .rs-header >
      .rs-navbar {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
      }

.layout
  .rs-container >
    .rs-content {
      padding: var(--spacer);
      margin: var(--spacer);
      background: var(--white);
      border-radius: var(--border-radius);
}

/*! FOOTER */
.layout
  .rs-container >
    .rs-footer {
      position: relative;
      padding: 0 var(--spacer) var(--spacer) var(--spacer);
    }
.footer-wrapper {
  height: 23px;
}
.footerTitle {
   text-align: center;
   font-size: var(--font-size);
}

/*! VERTICAL DIVIDER BETWEEN LEFT & RIGHT SIDES */
.layout
  .rs-container >
    .rs-content
      .left-side {
        padding-right: var(--spacer);
      }
.layout
  .rs-container >
    .rs-content
      .right-side {
        padding-left: var(--spacer);
        border-left: 2px solid var(--dirty-white);
      }

/*! SCROLLABLE CONTENT AREA */
.scrollable-div {
  overflow-y: auto !important;
  padding-right: var(--spacer);
  margin-top: var(--spacer);
  --total-spacer: calc(var(--spacer) * 2);
  --total-nav-height: calc(var(--nav-height) * 2);
  --total-excess: calc(var(--total-nav-height) + var(--total-spacer) + var(--results-title-height));
  --height: calc(100% - var(--total-excess));
  height: var(--height);
  line-height: 24px;
  /* ? minus the right side navigations, spacers and results title heights. */
}

/*! NAVBAR MENU */
/* separator under tab items */
.rs-nav-subtle.rs-nav-horizontal .rs-nav-waterline {
  border-top: var(--nav-mode-waterline-height) solid var(--dirty-white) !important;
}
/* navbar menu bg color */
.rs-header .rs-navbar.rs-navbar-inverse {
  background-color: var(--navbar-bg-color) !important;
}
/* menu item bg color, text color & size */
.rs-nav.rs-nav-default.rs-navbar-nav.rs-navbar-right.rs-nav-horizontal {
  right: 4px;
  font-family: 'Inconsolata';
}
.rs-header .rs-navbar .rs-nav-item .rs-nav-item-content {
  font-size: 18px !important;
  color: var(--title-color) !important;
  text-shadow: var(--title-shadow);
}
.rs-header .rs-navbar .rs-nav-item > .rs-nav-item-content:active,
.rs-header .rs-navbar .rs-nav-item > .rs-nav-item-content:focus {
  background-color: inherit !important;
  color: var(--title-color) !important;
}
.rs-header .rs-navbar .rs-nav-item > .rs-nav-item-content:hover,
.rs-header .rs-navbar .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:hover {
  background-color: inherit !important;
  color: var(--navbar-item-hover-color) !important;
}
/* header navbar top-right cornering */
.rs-header >
  .rs-navbar >
    .rs-navbar-body >
      .rs-navbar-right > ul >
        li.rs-nav-item:last-of-type:not(.rs-nav-item-disabled) >
          a.rs-nav-item-content {
            border-top-right-radius: var(--border-radius);
          }

/*! MODE TABS */
/* tab text color, shadow & size */
.rs-nav.rs-nav-subtle .rs-nav-item .rs-nav-item-content {
  font-size: var(--font-size) !important;
  font-weight: 700;
  color: var(--title-color) !important;
  text-shadow: var(--title-shadow);
}
/* tab text color on hover */
.rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:focus,
.rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:hover {
  color: var(--nav-item-hover-color) !important;
}
/* tab spacing between */
.rs-nav.rs-nav-subtle .rs-nav-item > .rs-nav-item-content {
  padding: 9px 18px !important;
  /* text-transform: uppercase; */
}
/* selected mode box shadow */
.rs-nav.rs-nav-subtle .rs-nav-item-active {
  height: calc(var(--nav-height) - var(--nav-mode-waterline-height));
  -webkit-box-shadow: var(--nav-mode-item-box-shadow);
  box-shadow: var(--nav-mode-item-box-shadow);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
/* selected tab text */
.rs-nav.rs-nav-subtle .rs-nav-item-active > .rs-nav-item-content {
  color: var(--selected-color) !important;
}
/* text shadow on selected tab text */
.rs-nav.rs-nav-subtle .rs-nav-item-active .rs-nav-item-text {
  text-shadow: var(--selected-shadow);
}
/* selected tab waterline */
.rs-nav.rs-nav-subtle .rs-nav-item > .rs-nav-item-content:before {
  height: var(--nav-mode-waterline-height) !important;
  background-color: var(--selected-color) !important;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

/*! SOURCE TABS */
/* spacing between nav items */
.right-side .rs-nav.rs-nav-default
  .rs-nav-item {
    margin: 0 var(--quarter-spacer);
}
/* std text color & size */
.right-side .rs-nav.rs-nav-default
  .rs-nav-item >
    .rs-nav-item-content {
      font-size: var(--font-size);
      color: var(--title-color);
      text-shadow: var(--title-shadow);
      border-radius: 3px !important;
}
/* hovered bg color */
.right-side .rs-nav.rs-nav-default
  .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) >
    .rs-nav-item-content:hover {
      background-color: transparent;
      color: var(--nav-item-hover-color);
}
.right-side .rs-nav.rs-nav-default
  .rs-nav-item-active {
    -webkit-box-shadow: var(--nav-source-item-box-shadow);
    box-shadow: var(--nav-source-item-box-shadow);
    border-radius: var(--border-radius);
}
/* selected bg color */
.right-side .rs-nav.rs-nav-default
  .rs-nav-item-active >
    .rs-nav-item-content {
      color: var(--selected-color);
      background-color: var(--selected-source-bg-color);
}

/*! PANEL */
/* panel item divider line */
.rs-panel.rs-panel-default::before {
  left: 0 !important;
  right: 0 !important;
  border-top: 1px solid var(--dirty-white) !important;
}
.rs-panel-body {
  padding: var(--half-spacer) 0 !important;
}
.right-side .scrollable-div .rs-panel-group >
  .rs-panel:first-child >
    .rs-panel-body {
      padding-top: 0px !important;
}
.new-line {
  display: flex;
  margin: var(--half-spacer) 0;
}
.right-side .rs-panel-group >
  .rs-panel:first-child >
    .rs-panel-body
      .new-line {
        margin-top: 0px !important;
}
.new-line >
  .col1 {
    width: var(--definitions-col1-width);
    color: var(--dark-gray);
    text-shadow: var(--line-title-shadow);
}
.new-line >
  .col2 {
    padding: 0 4px;
    width: var(--definitions-col2-width);
    color: var(--gray);
}
.new-line >
  .col3 {
    width: calc(100% - (var(--definitions-col1-width) + var(--definitions-col2-width)));
}
.new-line >
  .source {
    color: var(--blue-gray) !important;
    font-size: var(--muted-font-size) !important;
}
.example-panel-body >
  .rs-panel-body {
    padding: 10px 0 !important;
}

/*! LOADER */
.animate-loading-wrapper {
  margin-top: 9px;
  margin-bottom: 24px;
  color: var(--title-color);
}
.animate-loading-dots-wrapper {
 display: inline-block;
 font-size: var(--loading-dots-font-size);
 letter-spacing: -1px;
 margin-left: var(--loading-dots-spacer);
}

/*! SEARCH HISTORY TITLE */
.history-title-panel {
  color: var(--title-color);
  font-weight: 700;;
  text-shadow: var(--title-shadow);
}
.history-title-panel > .rs-panel-body {
  padding-top: 0 !important;
}
.history-title-panel::after {
  content: "";
  position: absolute;
  border-top: 2px solid var(--dirty-white);
  left: 0;
  right: var(--spacer);
}
#search-history .rs-panel-body {
  color: var(--dark-gray);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/*! RESULTS TITLE PANEL */
.results-title-panel {
  padding: var(--quarter-spacer) var(--quarter-spacer) 0;
}
.results-title-panel::after {
  content: "";
  position: absolute;
  border-top: 2px solid var(--dirty-white);
  left: var(--spacer);
  right: 0;
}
.resultsTitleText,
.textFound {
  color: var(--searched-text-color);
  text-shadow: var(--searched-text-shadow);
}

/* -- NO RESULTS TITLE PANEL -- */
.no-results-title-panel >
  .rs-panel-body {
    padding-top: 11px !important;
    padding-bottom: var(--half-spacer) !important;
    line-height: 1 !important;
}
.no-results {
  text-align: center;
  margin-top: var(--spacer);
}

.rs-btn-primary {
  background-color: magenta !important;
}

.filterColor {
  color: var(--filter-color) !important;
}

.app-logo {
  color: var(--logo-text-color);
  display: inline-flex;
  /* width: 144px; */
  height: var(--header-height);
  /* margin-right: var(--spacer); */
  /* background: url("./App/Assets/img/white-icon-read-glasses@24x24.png") no-repeat var(--spacer) center; */
  align-items: center;
  /* justify-content: flex-end; */
  padding: 5px 0 0 var(--spacer);
  font-family: 'Cinzel';
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1.2px;
}

.aikodo-icon {
  position: absolute;
  width: 76px;
  right: var(--spacer);
  bottom: calc(var(--spacer));
  background: url("./App/Assets/img/aikodo-gray-logo@24x24.png") no-repeat;
  background-size: contain;
  text-align: right;
  font-family: 'Inconsolata';
}

/*! HOTKEY INDICATOR */
.interaction-mode {
  position: absolute;
  display: flex;
  font-size: var(--font-size);
  overflow: hidden;
  color: var(--interaction-mode-hotkey-off-text-color);
}
.interaction-mode-text {
  display: flex;
  align-items: center;
  padding-left: var(--half-spacer);
  height: 22px;
  word-spacing: -3px;
  font-family: 'Inconsolata';
}
.hotkey-icon-on,
.hotkey-text-on {
  color: var(--interaction-mode-hotkey-on-text-color);
}
.esc-btn {
  background-color: var(--cobalt) !important;
  color: var(--white) !important;
  padding: 0px 6px !important;
  border-radius: 3px !important;
  line-height: 1.3 !important;
  font-size: 14px;
  padding: 2px 6px !important;
}
.esc-btn.active {
  background-color: var(--interaction-mode-hotkey-on-text-color) !important;
}

/*! SEARCH & FILTER INPUT TEXTBOX */
.rs-input {
  border: 1px solid var(--input-border-color) !important;
}
.rs-input:focus, .rs-input:hover {
  border-color: var(--input-border-color) !important;
}
.rs-input-lg {
  height: var(--input-height) !important;
  font-size: var(--font-size) !important;
}
/* input icon */
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  cursor: pointer;
  font-size: 10px !important;
  width: var(--input-width) !important;
  height: var(--input-height) !important;
  padding: 14px 14px 14px !important;
  background-color: var(--dirty-white) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rs-input-group-inside {
  margin-bottom: var(--spacer);
}

/*! BUTTONS */
.rs-btn-primary {
  background-color: var(--primary-button-color) !important;
}

/*! VERT. SCROLLBAR */
.scrollable-div.scroll-vertical::-webkit-scrollbar-track
{
  -webkit-box-shadow: var(--scrollbar-track-shadow);
  box-shadow: var(--scrollbar-track-shadow);
  border-radius: var(--border-radius);
  background-color: var(--dirty-white);
}
.scrollable-div.scroll-vertical::-webkit-scrollbar
{
  width: var(--scrollbar-width);
  background-color: var(--dirty-white);
}
.scrollable-div.scroll-vertical::-webkit-scrollbar-thumb
{
  border-radius: var(--border-radius);
  -webkit-box-shadow: var(--scrollbar-thumb-shadow);
  box-shadow: var(--scrollbar-thumb-shadow);
  background-color: var(--scrollbar-color);
}

/*! HORIZ. SCROLLBAR */
.scrollable-div.scroll-horizontal::-webkit-scrollbar-track
{
    background-color: transparent;
}
.scrollable-div.scroll-horizontal::-webkit-scrollbar
{
    height: var(--scrollbar-height);
    background-color: transparent;
}
.scrollable-div.scroll-horizontal::-webkit-scrollbar-thumb
{
    border-radius: var(--border-radius);
    background-color: var(--scrollbar-color);
}
/* --- */

/*! IMAGE GALLERY */
.image-gallery-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height is set dynamically by image viewFormat.js */
}
.image-frame {
  display: flex;
  align-items: flex-start;
}
.image-item {
  /* ignores dark-mode */
  z-index: 1000; /* must be more than .darkmode-layer */
  isolation: isolate;
}

/*! IMAGE GALLERY BUTTONS */
.image-gallery-nav {
  margin-top: calc(var(--spacer) * 2);
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--image-gallery-navbtn-wrapper-dims);
}
.image-gallery-nav > div[role="presentation"]{
  cursor: pointer;
}
.image-gallery-navbtn-wrapper {
  /* background-color: #c9cdcf; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex: none;
  flex: none;
  padding: 0;
  height: var(--image-gallery-navbtn-wrapper-dims);
  width: var(--image-gallery-navbtn-wrapper-dims);
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: var(--blue-gray);
  border-radius: 50%;
  -webkit-box-shadow: var(--image-gallery-navbtn-shadow);
  box-shadow: var(--image-gallery-navbtn-shadow);
  transition: visibility .3s,opacity .3s cubic-bezier(.1,.82,.25,1);
}
.image-gallery-navbtn {
  width: var(--image-gallery-navbtn-dims);
  height: var(--image-gallery-navbtn-dims);
}
/* .image-gallery-navbtn:hover {
  fill: var(--selected-color);
} */
.image-gallery-btn-svg-path {
  fill: #FFF;
}

.spelling-title {
  padding-bottom: var(--spacer);
}

.synsBox {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.synsCol {
  /* white-space: nowrap; */
}
.synsCol:not(:last-of-type) {
  margin-right: var(--spacer);
}
.synsCol .colHeader {
    border-bottom: solid 1px var(--smoke);
    display: inline-flex;
    margin-bottom: 7px;
}

/*! Highlight Tooltip Menu */
.popover {
  z-index: 998; /* needs to be less than darkmode-layer */
}
.highlight-on-hover:hover,
.highlight-on-hover:hover span {
  color: var(--word-hover-color);
  cursor: pointer;
}
.highlightMenuActive {
  display: inline-block;
  -webkit-transition: top 75ms ease-out,left 75ms ease-out;
  transition: top 75ms ease-out,left 75ms ease-out;
  -webkit-animation: pop-upwards 180ms forwards linear;
  animation: pop-upwards 180ms forwards linear;
}
.highlightMenu {
  -webkit-transition: none;
  transition: none;
}
.highlightMenuInner {
  /* background-image: var(--charcoal);
  background-repeat: repeat-x; */
  background-color: var(--highlight-menu-bg-color);
  -webkit-border-radius: var(--border-radius);
  border-radius: var(--border-radius);
  padding: 0;
}
.highlightMenu .highlightMenuArrowClip {
  position: absolute;
  bottom: -10px;
  left: 50%;
  clip: rect(10px 20px 20px 0);
  margin-left: -10px;
}
.highlightMenu .highlightMenuArrowClip .highlightMenuArrow {
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--highlight-menu-bg-color);
    -webkit-transform: rotate(45deg) scale(.5);
    transform: rotate(45deg) scale(.5);
}

.buttonHighlightMenu {
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  vertical-align: center;
  height: var(--highlight-menu-height);
  line-height: calc(var(--highlight-menu-height) + 2px);
  padding: var(--highlight-menu-y-padding) var(--highlight-menu-x-padding);
  margin: var(--highlight-menu-y-margin) var(--highlight-menu-x-margin);
  border: 0;
  /* font-size: 18px; */
  color: var(--highlight-menu-color);
  cursor: pointer;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.buttonSet > .buttonHighlightMenu {
  display: inline-block;
  vertical-align: middle;
}
.buttonHighlightMenu > i.rs-icon {
  /* font-size: 1em !important; */
  font-size: 18px !important;
}
.buttonSetSeparator {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  margin: 0px;
  height: 20px;
  background: var(--highlight-menu-separator-color);
}

/*! SVG Buttons */
.ok-badge-icon {
  padding-top: 6px;
  overflow: visible !important;
  margin-right: var(--half-spacer);
}
.ok-badge-icon-fill {
  fill: var(--blue-gray);
}

.hotkey-esc-btn {
  fill: var(--cobalt);
}

.emoji {
  z-index: 1000;
  isolation: isolate;
  font-size: var(--emoji-font-size);
}
.emoji-flat {
  color: transparent;
  text-shadow: var(--emoji-color);
}
.emoji-heart {
  text-shadow: 0 0 0 var(--emoji-heart-color);
}

/*! dark-mode library overrides */
.darkmode-layer, .darkmode-toggle {
  z-index: 999; /* does not work correctly without this. */
}
.darkmode-layer--button {
  transition: none !important; /* disable default transition effect */
  opacity: .89; /*? dimmerSlider controlled */
  bottom: 2.2rem !important;
}
.darkmode-toggle {
  width: 2.2rem !important;
  height: 2.2rem !important;
  right: calc(50vw - (2.2rem / 2)) !important;
  bottom: 2.077rem !important;
  padding-top: .223rem;
  color: var(--white);
  font-size: 1.3rem;
}
.darkmode-toggle > label {
  cursor: pointer;
}
.darkmode-toggle > label.light {
  color: var(--white);
}
.darkmode-toggle > label.dark {
  color: var(--body-text-color);
}

/*! dark-mode dimmer */
.darkmode-dimmer-slider {
  z-index: 1000;
  isolation: isolate;
  position: absolute !important;
  left: calc(50% + 1.1rem + 1rem) !important;
  bottom: 1.72rem;
}
.rs-slider-handle:before {
  border-color: var(--dimmer-handle-ring-color) !important;
  background-color: var(--dimmer-handle-bg-color) !important;
  width: 16px !important;
  height: 16px !important;
  top: -2px;
}
.rs-slider-handle:hover:before {
  box-shadow: 0 0 0 2px rgba(194, 237, 255, .1) !important;
}
.rs-slider-bar, .rs-slider-bar:hover {
  background-image: var(--dimmer-bar-bg-color) !important;
}

/*! SPACERS */
.horiz-spacer-div {
  width: var(--spacer);
}
.horiz-spacer-div-2 {
  width: calc(var(--spacer) * 2);
}
.vertical-spacer-div {
  height: var(--spacer);
}

/*! WHITESPACEHOLDER */
.whitespace-holder-title-wrapper {
  margin-top: 9px; /* same as .animate-loading-wrapper */
  margin-bottom: 24px; /* same as .animate-loading-wrapper */
  color: var(--dark-gray);
}
#whitespace-holder > .paragraph:first-child {
  margin-top: 1px !important;
}
.paragraph {
  margin-top: var(--quarter-spacer);
}
.paragraph,
.text-block {
  width: 100%;
}
.paragraph > .text-block .text-row {
  display: flex;
}
.paragraph > .text-block .text-row:not(:first-child) {
  margin-top: 0.7em;
}
.paragraph > .text-block .text-row:first-child {
  margin-top: 13px;
}
.paragraph > .text-block .text-row-item {
  max-height: 14.2857%;
  height: .7em;
  background-color: var(--dirty-white);
  opacity: 0.7;
  margin-top: 0px;
  border-radius: 3px;
}
.text-row-item.title-1 {
  width: 35%;
}
.text-row-item.title-2 {
  width: 50%;
}
.text-row-item.p-title {
  width: 5%;
  margin-right: 2%;
}
.text-row-item.p1 {
  width: 66%;
}
.text-row-item.p2 {
  width: 69%;
}
.text-row-item.p3 {
  width: 72%;
}
.paragraph > .text-block .circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--dirty-white);
}
.shimmer {
  animation-name: placeHolderShimmer;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  background: linear-gradient(
    to right,
    var(--dirty-white) 0%,
    var(--fume-white) 20%,
    var(--dirty-white) 40%,
    var(--dirty-white) 100%
  );
  background-size: calc(100% * 2) 100%;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}